<script setup lang="ts">
import { focusInputById } from "~/utils/input/focus"

const { t } = useI18n()
const title = t("loginSignup.checkUserPage.headTitle")
const {
  isLoading,
  userCheckError,
  handleLoginOrSignupFlow,
  email,
  canShowEmailCheckModal,
  isLoqateLoading,
  handleCloseModal,
  handleConfirmEmailModal
} = useLoginSignupCheck()

const { emailFromCookies } = useLoginOrSignupCheckedEmail()

if (emailFromCookies.value) {
  focusInputById("email-input")
}

const closeModalAndFocusInput = () => {
  handleCloseModal()
  focusInputById("email-input")
}
</script>

<template>
  <SeoBase :meta-title="title" />
  <UtilsLoginOrSignupWrapper :wrapper-image="bgLoginImage">
    <LoginSignupOrganismSocialLogin />
    <UtilsLoginOrSignupOrText />
    <UtilsLoginOrSignupPageIntroText />
    <LoginSignupOrganismFormUserCheck
      :is-loading="isLoqateLoading || (isLoading && !canShowEmailCheckModal)"
      :email="emailFromCookies"
      :handle-submit="handleLoginOrSignupFlow"
    />
    <UtilsSimpleErrorParagraph
      v-if="userCheckError.error && userCheckError.message"
      :message="userCheckError.message"
    ></UtilsSimpleErrorParagraph>
  </UtilsLoginOrSignupWrapper>
  <DialogsAndModalsCheckEmail
    v-if="canShowEmailCheckModal"
    :email="email"
    :is-button-success-loading="isLoading"
    @on-cancel="closeModalAndFocusInput"
    @on-confirm="handleConfirmEmailModal"
  />
</template>
