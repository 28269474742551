export const useCheckEmailWithLoqate = () => {
  const isEmailValid = ref(false)
  const isLoqateLoading = ref(false)

  const setIsEmailValid = (status: boolean) => {
    isEmailValid.value = status
  }

  const setIsLoqateLoading = (status: boolean) => {
    isLoqateLoading.value = status
  }

  const checkEmailWithLoqate = async (email: string) => {
    setIsLoqateLoading(true)
    const { emailValidation } = useLoqate()
    const isValid = await emailValidation(email)
    setIsEmailValid(isValid)
    setIsLoqateLoading(false)

    return isValid
  }

  return {
    isEmailValid,
    isLoqateLoading,
    setIsEmailValid,
    setIsLoqateLoading,
    checkEmailWithLoqate
  }
}
