import { apiPaths } from "~/utils/paths/api-paths"

export interface LoginSignupUserCheckResponse {
  email: string
  isUser: boolean
  isLegacyUser: boolean
}

export async function checkUserEmailForLoginOrSignup(
  email: string
): Promise<LoginSignupUserCheckResponse> {
  const params = {
    email
  }

  return await $fetch(apiPaths.userCheck, {
    params
  })
}
