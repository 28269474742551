import { appPaths } from "~/utils/paths/paths"
import { checkUserEmailForLoginOrSignup } from "~/utils/fetch/account/userCheck"
import { loginOrSignUpCheckedEmailStorageKey } from "~/utils/loginSignup/loginSignup"
import Cookies from "js-cookie"
import { getBreadcrumbsItems } from "~/utils/breadcrumbs/breadcrumbs"

export default function useLoginSignup() {
  const { t } = useI18n()
  const {
    email,
    isLoading,
    isEmailFormSent,
    canShowEmailCheckModal,
    isEmailValid,
    isLoqateLoading,
    checkEmailWithLoqate,
    setIsEmailFormSent,
    setEmail,
    setIsLoading
  } = useCheckEmailForm()

  const defaultUserCheckError = {
    error: false,
    message: ""
  }

  const userCheckError = ref(defaultUserCheckError)
  const breadcrumbsLinks = getBreadcrumbsItems("Accedi o registrati")

  const setUserCheckErrorValue = (error = false, message = "") => {
    userCheckError.value = {
      ...userCheckError.value,
      error,
      message
    }
  }

  const handleLoginOrSignupFlow = async (fields: { email: string }) => {
    const { email } = fields
    setEmail(email)
    setUserCheckErrorValue()
    await checkEmailAndStartFlow(email)
  }

  const handleCloseModal = () => {
    setIsEmailFormSent(false)
  }

  const handleConfirmEmailModal = () => {
    loginOrSignup(email.value)
  }

  const checkEmailAndStartFlow = async (email: string) => {
    if (!email) {
      return
    }

    const isValid = await checkEmailWithLoqate(email)

    setIsEmailFormSent(true)
    if (!isValid) {
      setIsLoading(false)
      return
    }

    await loginOrSignup(email)
  }

  const loginOrSignup = async (email: string) => {
    setIsLoading(true)

    try {
      const userCheckResponse = await checkUserEmailForLoginOrSignup(email)

      Cookies.set(loginOrSignUpCheckedEmailStorageKey, email)

      if (userCheckResponse.isUser || userCheckResponse.isLegacyUser) {
        const loginPath = `${appPaths.login}`
        return await navigateTo(loginPath)
      }

      const signupPath = `${appPaths.signup}`
      setIsLoading(false)
      setIsEmailFormSent(false)

      await navigateTo(signupPath)
    } catch (e) {
      Cookies.remove(loginOrSignUpCheckedEmailStorageKey)
      setIsLoading(false)
      setUserCheckErrorValue(true, t("general.errorMessage"))
    }
  }

  return {
    isLoading,
    userCheckError,
    breadcrumbsLinks,
    isEmailValid,
    email,
    isEmailFormSent,
    canShowEmailCheckModal,
    isLoqateLoading,
    setIsEmailFormSent,
    handleLoginOrSignupFlow,
    handleCloseModal,
    handleConfirmEmailModal
  }
}
