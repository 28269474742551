<script setup lang="ts">
import { socialLogin } from "~/config/social-login"
import {loginMethod, socialLoginStorageKey} from "~/utils/analytics/customer";

const googleLoginPath = socialLogin(loginMethod.google.toUpperCase())
const facebookLoginPath = socialLogin(loginMethod.facebook.toUpperCase())
const appleLoginPath = socialLogin(loginMethod.apple.toUpperCase())

const handleSocialLogin = (method: string) => {
  localStorage.setItem(socialLoginStorageKey, method)
}
</script>

<template>
  <div class="flex w-full flex-col items-center gap-2">
    <div class="mb-2 w-full space-y-4 text-start">
      <h3 class="dolphin-bold">
        {{
          $t("dialogsAndModals.organism.loginAndRegistration.registrationCta")
        }}
      </h3>

      <p class="mouse">
        {{ $t("dialogsAndModals.organism.loginAndRegistration.istruction") }}
      </p>
    </div>

    <UtilsButton
      :path="googleLoginPath"
      order="last"
      class="custom-btn-google-login w-full"
      :text="$t('dialogsAndModals.organism.loginAndRegistration.googleLogIn')"
      force-self
      @click="handleSocialLogin(loginMethod.google)"
    >
      <template #icon>
        <div class="ml-1 h-6 w-6">
          <UtilsIcon name="GoogleSquare.svg" />
        </div>
      </template>
    </UtilsButton>

    <UtilsButton
      :path="facebookLoginPath"
      order="last"
      class="custom-btn-facebook-login w-full"
      :text="$t('dialogsAndModals.organism.loginAndRegistration.facebookLogIn')"
      force-self
      @click="handleSocialLogin(loginMethod.facebook)"
    >
      <template #icon>
        <div class="ml-1 h-6 w-6">
          <UtilsIcon name="FacebookSquare.svg" />
        </div>
      </template>
    </UtilsButton>

    <UtilsButton
      :path="appleLoginPath"
      order="last"
      class="custom-btn-apple-login w-full"
      :text="$t('dialogsAndModals.organism.loginAndRegistration.appleLogIn')"
      force-self
      @click="handleSocialLogin(loginMethod.apple)"
    >
      <template #icon>
        <div class="ml-1 h-6 w-6">
          <UtilsIcon name="AppleSquare.svg" />
        </div>
      </template>
    </UtilsButton>
  </div>
</template>
