<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { focusInputById } from "~/utils/input/focus"
import { FormUserCheckProps } from "./FormUserCheck.props"

const props = withDefaults(defineProps<FormUserCheckProps>(), {
  email: ""
})

const emailField = ref(props.email)

const handleInvalidSubmit = () => {
  focusInputById("email-input")
}
</script>

<template>
  
<FormKitLazyProvider config-file="true">
<FormKit
    type="form"
    id="loginOrSignupForm"
    :actions="false"
    form-class="flex w-full flex-col gap-4"
    :incomplete-message="false"
    @submit="handleSubmit"
    @submit-invalid="handleInvalidSubmit"
  >
    <TextfieldsEmail
      id="email-input"
      name="email"
      :loqate-check="false"
      :label="$t('loginSignup.emailField')"
      class="md:w-full"
      v-model="emailField"
    />
    <UtilsButton
      type="submit"
      class="h-10 w-full"
      :text="$t('loginSignup.continue')"
      :is-loading="isLoading"
    >
      <template #icon>
        <UtilsLoader v-if="isLoading" class="mr-2" />
      </template>
    </UtilsButton>
  </FormKit>
</FormKitLazyProvider>

</template>
