<script lang="ts" setup>
import Wrapper from "~/components/Utils/LoginOrSignup/Wrapper.vue"

const isLogged = useIsLogged().value

definePageMeta({
  middleware: "logged-in-redirect-home"
})
</script>

<template>
  <PagesLoginOrSignupCheck v-if="!isLogged" />
  <Wrapper v-else>
    <SkeletonAccountArea />
  </Wrapper>
</template>
