export const useCheckEmailForm = () => {
  const { checkEmailWithLoqate, isEmailValid, isLoqateLoading } =
    useCheckEmailWithLoqate()

  const email = ref("")
  const isLoading = ref(false)
  const isEmailFormSent = ref(false)

  const setIsEmailFormSent = (status: boolean) => {
    isEmailFormSent.value = status
  }

  const setIsLoading = (status = true) => {
    isLoading.value = status
  }

  const setEmail = (emailVal: string) => {
    email.value = emailVal
  }

  const canShowEmailCheckModal = computed(
    () => !isEmailValid.value && isEmailFormSent.value
  )

  return {
    email,
    isLoading,
    isEmailFormSent,
    canShowEmailCheckModal,
    isEmailValid,
    isLoqateLoading,
    checkEmailWithLoqate,
    setIsEmailFormSent,
    setIsLoading,
    setEmail
  }
}
